<template>
  <div>
    <v-data-table
        :loading="loading"
        :headers="datatableHeaders"
        :items="delegates"
        class="elevation-1"
    >
      <template v-slot:item="{item}">
        <tr>
          <td style="width: 250px;">
            <v-select
                outlined
                style="margin-top: 20px;"
                :items="filteredTemplates"
                v-model="item.openSliderUuid"
                item-value="uuid"
                item-text="alias"
                @change="update"
            ></v-select>
          </td>
          <td style="width: 250px;">
            <v-select
                outlined
                style="margin-top: 20px;"
                :items="filteredTemplates"
                v-model="item.closedSliderUuid"
                item-value="uuid"
                item-text="alias"
                @change="update"
            ></v-select>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {ENDPOINTS} from "@/config";
import mixin from "@/mixins/KeyboardMixIns";
import {mapState} from "vuex";

export default {
  name: "KioskImagesTemplatesAssignmentComponent",

  components: {},

  mixins: [mixin],

  data() {
    return {
      ENDPOINTS,
      loading: false,
      // --- Datatable ---
      datatableHeaders: [
        { text: this.$t('generic.lang_open'), value: 'openSliderUuid' },
        { text: this.$t("generic.lang_closed"), value: 'closedSliderUuid' },
      ],
      template: null,
      delegates: [],
      templates: {},
    }
  },

  mounted() {
    this.getTemplates();
  },

  computed: {
    ...mapState([
      'api'
    ]),
    filteredTemplates() {
      let templates = [];

      for (let template in this.templates) {
        if (!this.templates.hasOwnProperty(template)) {
          continue;
        }

        template = this.templates[template];
        templates.push({
          uuid: template.uuid,
          alias: template.alias,
        });
      }

      return templates;
    }
  },
  methods: {
    getTemplates() {
      this.loading = true;

      this.axios.post('get/kiosk/kioskSlideTemplates/').then((res) => {
        if (res.data.success) {
          this.templates = res.data.data;
          this.getDelegates();
        }

        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    getDelegates() {
      this.loading = true;
      this.delegates=[];
      this.axios.post('get/kiosk/delegateKioskSlides/').then((res) => {
        if (res.data.STATUS === 'SUCCESS') {
          this.delegates.push(res.data.data);
        }
      }).catch(() => {
      }).finally(() => {
        this.loading = false;
      })
    },

    update() {
      this.loading = true;
      this.axios.post('update/kiosk/kioskSlideTemplate/delegate/',this.delegates[0]).then((res) => {
        if (res.data.success) {
          this.templates = res.data.data;
        }

        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
  }
}
</script>