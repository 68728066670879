<template>
  <div>
    <page-title :heading="$t('kiosk.lang_kioskImagesTemplates')"  :subheading="$t('kiosk.lang_kioskImagesTemplates')" :icon=icon></page-title>

    <div class="app-main__inner">
      <v-card flat tile>
        <v-tabs v-model="tab" background-color="transparent">
          <v-tab key="list">
            {{ $t('settings.lang_templates') }}
          </v-tab>

          <v-tab key="templates">
            {{ $t('settings.lang_templatesAssignment') }}
          </v-tab>

          <v-tabs-items touchless v-model="tab">
            <v-tab-item key="list">
              <KioskImagesTemplatesComponent/>
            </v-tab-item>

            <v-tab-item key="templates">
              <KioskImagesTemplatesAssignmentComponent/>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-card>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle.vue";
import KioskImagesTemplatesComponent from "../../components/kiosk/settings/imagesupload/KioskImagesTemplatesComponent";
import KioskImagesTemplatesAssignmentComponent
  from "../../components/kiosk/settings/imagesupload/KioskImagesTemplatesAssignmentComponent";

export default {
  name: "KioskImagesTemplates",

  components: {
    KioskImagesTemplatesAssignmentComponent,
    KioskImagesTemplatesComponent,
    PageTitle,
  },

  data: () => ({
    icon: 'pe-7s-monitor icon-gradient bg-tempting-azure',
    tab:0,
  }),
  methods:{

  },
  mounted(){
  }
}
</script>
