<template>
  <div>
    <v-btn style="margin: 20px;" color="success" :disabled="createLoading" :loading="createLoading"
           @click="showCreateDialog=true">
      <v-icon>add</v-icon>
      {{ $t('erp.lang_newTemplate') }}
    </v-btn>

    <v-data-table
        :loading="loading"
        :headers="datatableHeaders"
        :items="filteredTemplates"
        class="elevation-1"
    >
      <template v-slot:header.id="{ header }">

      </template>
      <template v-slot:item="{item}">
        <tr>
          <td style="width: 200px;">{{ item.alias }}</td>
          <td style="text-align: right;">
            <v-btn @click="editTemplate(item)" icon text color="primary">
              <v-icon>edit</v-icon>
            </v-btn>
            <v-btn :disabled="item.count > 0" @click="deleteTemplate = item" icon text color="error">
              <v-icon>delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog persistent max-width="400" v-model="showCreateDialog">
      <v-card>
        <v-card-title>{{$t('settings.lang_createTemplate')}}</v-card-title>
        <v-card-text class="text-center">
          <v-text-field v-model="templateName" dense outlined :label="$t('erp.lang_TemplateName')"  />
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" text :disabled="loading" @click="showCreateDialog = false">{{ $t('generic.lang_cancel') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" :disabled="loading" :loading="loading"
                 @click="createTemplate">{{ $t('generic.lang_add') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent max-width="400" :value="deleteTemplate !== null">
      <v-card>
        <v-card-title>{{$t('settings.lang_deleteTemplate')}}</v-card-title>
        <v-card-text class="text-center">
          {{$t('generic.lang_actionCannotbeUndone')}}
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" text :disabled="loading" @click="deleteTemplate = null">{{ $t('generic.lang_cancel') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" :disabled="loading" :loading="loading"
                 @click="deleteKisokImagesTemplate(deleteTemplate)">{{ $t('generic.lang_delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {ENDPOINTS} from "@/config";
import mixin from "@/mixins/KeyboardMixIns";
import {mapState} from "vuex";

export default {
  name: "KioskImagesTemplatesComponent",

  mixins: [mixin],

  data() {
    return {
      ENDPOINTS,
      loading: false,
      createLoading: false,
      showCreateDialog: false,
      templateName: null,
      // --- Datatable ---
      datatableHeaders: [
        {
          text: 'ID',
          align: 'left',
          value: 'id',
          width: 80,
          hide: true
        },
        {align: 'left',text: this.$t('generic.lang_alias'), value: 'alias'},
      ],
      templates: [],
      deleteTemplate: null
    }
  },

  mounted() {
    this.getTemplates();
  },

  computed: {
    ...mapState([
      'api'
    ]),
    filteredTemplates() {
      let templates = [];

      for (let template in this.templates) {
        if (!this.templates.hasOwnProperty(template)) {
          continue;
        }

        template = this.templates[template];
        templates.push({
          id: template.uuid,
          alias: template.alias,
        });
      }

      return templates;
    }
  },

  methods: {
    getTemplates() {
      this.loading = true;

      this.axios.post(ENDPOINTS.KIOSK.IMAGETEMPLATES.GETALL).then((res) => {
        if (res.data.success) {
          this.templates = res.data.data;
        }

        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    deleteKisokImagesTemplate(template) {
      this.loading = true;

      this.axios.post(ENDPOINTS.KIOSK.IMAGETEMPLATES.DELETE, {
        slideID: template.id
      }).then((res) => {
        if (res.data.success) {
          this.deleteTemplate = null;
          this.getTemplates();
        }

        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    editTemplate(template) {
      this.$router.push({
        name: "kiosk.kisokImagesTemplate.edit",
        params: {
          id: template.id
        }
      });
    },
    createTemplate() {
      this.createLoading = true;

      this.axios.post(ENDPOINTS.KIOSK.IMAGETEMPLATES.CREATE, {
        alias: this.templateName,
      }).then((res) => {
        if (res.data.success) {
          this.getTemplates();
          this.showCreateDialog = false;
          this.templateName = null;
        }else{

        }
      }).catch(() => {

      }).finally(()=>{
        this.createLoading = false;
      })
    }
  }
}
</script>